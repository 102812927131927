import styles from './FAQs.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";

const anq = [
    { ques: "Do films need to be in English?", ans: "All films must be submitted in English or with English subtitles (if in a different language)." },
    { ques: "What is the production timeframe for eligible films?", ans: "All films must have been made in or after 2019." },
    { ques: "What is the maximum duration for short films?", ans: "Short films must not exceed 45 minutes." },
    { ques: "Who is responsible for copyright issues?", ans: "Filmmakers are responsible for sorting out the copyright on their own materials." },
    { ques: "What rights do filmmakers grant by submitting their films?", ans: "By submitting to us, you give us consent and the right to screen your film in front of a live audience, and, under special circumstances, for online screening." },
    { ques: "What are nominations?", ans: "Nominations: These are all the films that have been nominated for an award in a category. Not all nominated films will be screened." },
    { ques: "What is the Official Selection?", ans: "Official Selection: These are all the nominated films selected for screening, which will be notified via email. If you receive a Nomination email but no Official Selection email, it means your film will not be screened but is still a contender for an award in its category." },
    { ques: "How are award winners chosen?", ans: "Award Winners: Chosen from the Nominations (which includes the Official Selection)." },
    { ques: "Can the festival change the date and venue?", ans: "We retain the right to change the date and venue of the festival under special circumstances. All filmmakers will be informed about any changes through email and our online platforms." },
    { ques: "Are there any restrictions on the premiere status of films?", ans: "We place no restrictions on your work in terms of premiere status or whether your work has been made available online." },
    { ques: "What types of screeners are not accepted?", ans: "We do not accept partial screeners, trailers, or screener links that require access other than password-protected links." },
    { ques: "Who is responsible for technical issues with the submitted films?", ans: "Any technical issues with the submitted films are 100% the responsibility of the filmmakers. If, at the time of judging, your video file doesn’t work, it will be automatically rejected without a refund. Please contact the festival team if there are any issues with the submission process, and a solution will be found." },
    { ques: "What do filmmakers agree to by submitting?", ans: "By submitting to us, you agree to our terms and conditions and to receiving marketing newsletters and discounts about film festivals in the form of emails. We will never sell your information to third parties." },
    { ques: "Can the terms and conditions be updated?", ans: "We reserve the right to update our terms and conditions." }
];

const FAQs = () => {
    return(
            <Container>
                <div className={styles.wrapper} id={'faqs'}>
                    <div className={styles.inner}>
                        <div className={styles.title}>Rules & FAQs</div>
                        <div className={styles.subTitle}>Find answers to common questions about the festival, ticketing, and submissions.</div>
                        <div className={styles.faqs}>
                            {anq.map((faq, index) => (
                <Show>

                                <div key={index} className={styles.faq}>
                                    <div className={styles.question}>{faq.ques}</div>
                                    <div className={styles.answer}>{faq.ans}</div>
                                </div>
                </Show>

                            ))}
                        </div>
                    </div>
                </div>
            </Container>
    );
};

export default FAQs;
