import React, { useState, useEffect } from 'react';
import styles from './Header.module.scss';
import Container from "../../UI/Container";
import MobileMenu from '../../UI/MobileMenu';

const Header = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const handleClick = (e, href) => {
        e.preventDefault();
        const element = document.querySelector(href);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const items = [
        { value: "About", href: '#about' },
        { value: "Awards", href: '#awards' },
        { value: "FAQs", href: '#faqs' },
        { value: "Venues", href: '#venues' },
        { value: "Contact", href: '#contact' },
    ];

    return (
        <div className={`${styles.wrapper} ${scrolled ? styles.scrolled : ''}`}>
            <Container>
                <div className={styles.inner}>
                    <div className={styles.logo}>
                        <img src={"/images/Copenhagen.png"} alt="Logo" />
                        <div className={styles.headerItems}>
                            {items.map((item, index) => (
                                <div className={styles.items} key={index}>
                                    <a href={item.href} onClick={(e) => handleClick(e, item.href)}>
                                        {item.value}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                    <a href="https://filmfreeway.com/copenhagenindependentfilmfestival">
                        <div className={styles.button}>Submit Now</div>
                    </a>
                </div>
                <MobileMenu active={menuActive} setActive={setMenuActive} items={items} />
                <div className={styles.mobileMenu} onClick={() => setMenuActive(!menuActive)}>
                    <div className={`${styles.burgerButton} ${menuActive ? styles.active : ''}`}>
                        <span></span>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Header;
