import React, { useState, useEffect } from 'react';
import Header from './components/blocks/Header';
import Banner from './components/blocks/Banner';
import Experience from './components/blocks/Experience';
import About from './components/blocks/About';
import Awards from './components/blocks/Awards';
import Venues from './components/blocks/Venues';
import FAQs from './components/blocks/Rules';
import Still from './components/blocks/Still';
import Footer from './components/blocks/Footer';
import Aiming from './components/blocks/Aiming';
import { ClipLoader } from 'react-spinners'; // Импортируем спиннер
import './App.css'; // Подключение файла стилей

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1700); // 5000 ms = 5 seconds

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, []);

  if (loading) {
    return (
      <div className="loading-screen">
        <ClipLoader size={150} color={"#ffff"} loading={loading} />
        <h1 className="loading-text">COPENHAGEN INDEPENDENT FILM FESTIVAL</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <Banner />
      <Experience />
      <About />
      <Awards />
      <Venues />
      <Aiming />
      <FAQs />
      <Still />
      <Footer />
    </div>
  );
}

export default App;
