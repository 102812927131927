import styles from './Banner.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const Banner = () => {
    return(
        <div className={styles.wrapper}>
            <Container>
            <div className={styles.inner}>
            <Show>
            <div className={styles.tittle}>Don't miss <b>Copenhagen Independent</b> film festival</div>
            </Show>
            <Show>
            <div className={styles.subTittle}> March 18, 2025</div>
            </Show>
            <div className={styles.buttons}>
            </div>
            </div>
            </Container>
        </div>
    );
};

export default Banner