import 'leaflet/dist/leaflet.css';
import styles from './Venues.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const Venues = () => {
    return(
        <Container>
        <Show>
        <div className={styles.wrapper} id={'venues'}>
            <div className={styles.title}>Venues</div>
            <div className={styles.map}>
                <img src="/images/mao.png"/>
            </div>
            <div className={styles.descriptionTitle}>Husets Biograf, Copenhagen, Denmark</div>
        </div>
        </Show>
        </Container>
    );
};

export default Venues