import styles from './About.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const About = () => {
    return(
        <Container>
        <Show>
        <div className={styles.wrapper} id={'about'}>
            <div className={styles.words}>
            <div className={styles.tittle}>About Us</div>
            <div className={styles.text}>
            The Copenhagen Independent Film Festival (CPHIFF) is a vibrant event supporting independent cinema from around the world, with a special focus on DIY (Do-It-Yourself) teams. These passionate filmmakers, often working with limited budgets and minimal equipment, bring a unique freshness and intensity to their work that sets them apart from mainstream cinema.

            </div>
            <div className={styles.text}>
            Fresh and Relevant Films: We showcase films made no earlier than 2019, ensuring the content is current and reflects the latest trends and stories from the independent film scene.
Diverse Genres: Our festival is open to submissions from any country and in various genres, highlighting the richness of global storytelling.
            </div>
            </div>
            <div className={styles.logo}>
            <img src={"/images/back.png"}/>
            </div>
        </div>
        </Show>
        </Container>
    );
};

export default About